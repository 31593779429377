export enum BlockInputOutputType {
  File = 'file',
  Text = 'text',
  Image = 'image',
  Audio = 'audio',
  Data = 'data',
  Vector = 'vector',
  Entity = 'entity',
  Relation = 'relation',
  Search = 'search',
  Index = 'index',
  Tool = 'tool',
  Context = 'context',
  Wait = 'wait',
  WaitTrue = 'wait-true',
  WaitFalse = 'wait-false',
}

export enum BlockType {
  // Trigger
  ObjectStorageUpload = 'object-storage-upload',
  ExternalStorageUpload = 'external-storage-upload',
  Upload = 'upload',
  // Storage
  ObjectStorage = 'object-storage',
  KeyValueDatabase = 'key-value-database',
  VectorDatabase = 'vector-database',
  GraphDatabase = 'graph-database',
  // Model
  Model = 'model',
  // Agent
  Agent = 'agent',
  Router = 'router',
  // Data Collection
  Tool = 'tool',
  DataCollection = 'data-collection',
  Knowledge = 'knowledge',
  // Function
  DocumentParser = 'document-parser',
  ChunkSplitter = 'chunk-splitter',
  SentenceSplitter = 'sentence-splitter',
  WordSplitter = 'word-splitter',
  EntityExtractor = 'entity-extractor',
  RelationExtractor = 'relation-extractor',
  Reranker = 'reranker',
  Embed = 'embed',
  // System
  Prompt = 'prompt',
  InputAudioStream = 'input-audio-stream',
  Context = 'context',
  Answer = 'answer',
  OutputAudioStream = 'output-audio-stream',
  // Skill
  AgentSkill = 'agent-skill',
  Code = 'code',
  Search = 'search',
  AiSearch = 'ai-search',
  WebScraper = 'web-scraper',
  WebScraperRaw = 'web-scraper-raw',
  CustomSkill = 'custom-skill',
  QueryFile = 'query-file',
  WriteFile = 'write-file',
  Form = 'form-skill',
}

export interface Blocks {
  agents: Block;
  dataCollections: Block;
  functions: Block;
  knowledges: Block;
  models: Block;
  outputs: Block;
  routers: Block;
  skills: Block;
  storages: Block;
  triggers: Block;
}

export interface Block {
  [key: string]: BlockConfig;
}

interface BlockIOTypes {
  name: string;
  inputTypes?: BlockInputOutputType[];
  outputTypes?: BlockInputOutputType[];
  attributes?: { [key: string]: string | number | boolean; } | undefined;
}

interface WorkflowBlockIOTypes {
  name: string;
  inputTypes?: BlockInputOutputType[];
  outputTypes?: BlockInputOutputType[];
  supportedTypes?: string[];
  attributes?: { [key: string]: string | number | boolean; } | undefined;
}

interface BlockConfig {
  ingest?: BlockIOTypes;
  retrieve?: BlockIOTypes;
  workflow?: WorkflowBlockIOTypes;
  [key: string]: any;
}

export interface BlockAttributes {
  [key: string]: string;
}
