import { BlockType } from 'src/app/models/blocks/base';

import { Workflow } from 'src/app/models/resources/workflow';
import { DataCollection } from 'src/app/pages/data-collection/data-collection.page';

interface VersionedResource<T> {
  versions?: T[];
}

export function getVersionLabel(version: number): string {
  return `${version}`;
}

export function getVersionFromLabel(label: string): number {
  return parseInt(label, 10);
}

export function getAllVersions(versions?: any[]): string[] {
  return Array.from({ length: versions?.length ?? 1 }, (_, i) => getVersionLabel((versions?.length ?? 1) - i));
}

export function isInWorkflow(type: BlockType, id: string): (workflow: Workflow) => boolean {
  return (workflow: Workflow) =>
    workflow.blocks?.some((block) => block.type === type && block.id === id) ||
    // workflow.versions?.some((version) => version.blocks?.some((block) => block.type === type && block.id === id)) ||
    false;
}

export function isInDataCollection(type: BlockType, id: string): (dataCollection: DataCollection) => boolean {
  return (collection: DataCollection) =>
    collection.ingestBlocks?.some((block: any) => block.type === type && block.id === id) ||
    collection.retrieveBlocks?.some((block: any) => block.type === type && block.id === id) ||
    // collection.versions?.some(
    //   (version: any) => version.ingestBlocks?.some((block: any) => block.type === type && block.id === id) ||
    //   version.retrieveBlocks?.some((block: any) => block.type === type && block.id === id)
    // ) ||
    false;
}

export function getResourceVersion<T extends VersionedResource<T>>(resource: T | T[], version?: number): T {
  if (resource instanceof Array) {
    if (!version) {
      throw new Error('Version is required for array resources');
    }
    return resource[version - 1];
  }

  return (version && resource.versions) ? resource.versions[version - 1] : resource;
}
