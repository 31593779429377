import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AccountService } from 'src/app/services/account/account.service';

import { DataStoreService } from 'src/app/services/data-store/data-store.service';
import { OrganizationMemberService, OrganizationRole } from 'src/app/services/organization-member/organization-member.service';
import { UserService } from 'src/app/services/user/user.service';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(
    private router: Router,

    private account: AccountService,
    private organizationMemberService: OrganizationMemberService,
    private user: UserService,
    private workflowService: WorkflowService,
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const currentUser = await this.user.getCurrentUserDocument();
    if (!currentUser) {
      await this.router.navigate(['/login']);
      return false;
    }

    const currentAccount = await this.account.getCurrentAccount();
    const organizationId = currentAccount?.organizationId;

    if (!organizationId) {
      return true;
    }

    const member = await this.organizationMemberService.getOrganizationMember(organizationId, currentUser.userId);
    const allowedRoles = next.data['allowedRoles'] as OrganizationRole[];

    if (allowedRoles.includes(member.role)) {
      return true;
    } else {
      try {
        const { vpmId, id: workflowId } = await this.workflowService.getDefaultWorkflow();
        await this.router.navigate([`/chat/${currentAccount.id}/${vpmId}/${workflowId}`]);

        return false;
      } catch (error) {
        await this.router.navigate([`/chat/${currentAccount.id}/404`]);
        return false;
      }
    }
  }
}
