import { Injectable } from '@angular/core';
import { getDoc, doc, collection, setDoc, updateDoc, serverTimestamp, query, where, getDocs, runTransaction, arrayUnion, deleteField, arrayRemove, deleteDoc } from 'firebase/firestore';

import { runWithRetry } from 'src/app/helpers/run-with-retry';

import { Account } from 'src/app/models/account';
import { Organization } from 'src/app/models/organization';
import { State } from 'src/app/models/state';

import { DataStoreService } from 'src/app/services/data-store/data-store.service';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { UserService } from 'src/app/services/user/user.service';

export enum AccountErrorCodes {
  AccountNotFound = 'account-not-found',
  LicenseNotAccepted = 'license-not-accepted',
  UserNotFound = 'user-not-found',
  UserDataNotFound = 'user-data-not-found',
  UserNotLoggedIn = 'user-not-logged-in',
  ClusterNotFound = 'cluster-not-found',
  Unknown = 'unknown',
}

export class AccountError extends Error {
  constructor(readonly code: AccountErrorCodes) {
    super(code);
    this.name = 'AccountError';
  }
}

// TODO - Hack because you don't have a default account before accepting the license
// WEHSOULD - Probably create the account on login and only display keys after accepting license like the organization flow
const defaultAccount: Account = {
  id: '',
  name: 'Default Account',
  ownerId: '',
  vendorId: '',
  accessToken: '',
  createdAt: new Date(),
}

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private readonly currentAccountKey = 'currentAccount';
  private readonly DEFAULT_ACCOUNT_NAME = 'Default Account';
  private apiKey?: string;

  constructor(
    private dataStore: DataStoreService,
    private firebase: FirebaseService,
    private userService: UserService,
  ) {
    // TODO org - Remove this reload once the state is properly managed when switching account
    this.dataStore.getState$().subscribe(async ({ newState, oldState }) => {
      const oldAccount = oldState?.currentAccount;
      const newAccount = newState.currentAccount;

      if (oldAccount && (oldAccount.id.length > 0 || oldAccount.name === 'Default Account') && newAccount && oldAccount.id !== newAccount.id) {
        await new Promise(resolve => setTimeout(resolve, 1));
        window.location.reload();
      }
   });
  }

  getCurrentAccountIdFromSession(): string | null {
    return this.dataStore.getAuthStateProperty(this.currentAccountKey, false);
  }

  async setCurrentAccountInSession(accountId: string): Promise<void> {
    this.dataStore.setAuthStateProperty(this.currentAccountKey, accountId, false);
    await this.setCurrentAccountOnUser(accountId);
  }

  async deleteCurrentAccountInSession(): Promise<void> {
    this.dataStore.removeAuthStateProperty(this.currentAccountKey);
    await this.deleteCurrentAccountOnUser();
  }

  async deleteAccount(accountId: string | undefined): Promise<void> {
    if (!accountId) {
      throw new AccountError(AccountErrorCodes.AccountNotFound);
    }

    try {
      const db = this.firebase.firestore;
      const accountRef = doc(db, 'developers', accountId);
      await deleteDoc(accountRef);
    } catch (e) {
      console.error(e);
      throw new Error('AccountService: Failed to delete account');
    }
  }


  async setCurrentAccountOnUser(currentAccountId: string): Promise<void> {
    const currentUser = this.firebase.auth.currentUser;
    if (!currentUser) {
      throw new AccountError(AccountErrorCodes.UserNotFound);
    }

    const userRef = doc(this.firebase.firestore, 'users', currentUser.uid);
    await setDoc(
      userRef,
      { currentAccountId },
      { merge: true }
    );
  }

  async deleteCurrentAccountOnUser(): Promise<void> {
    const currentUser = this.firebase.auth.currentUser;
    if (!currentUser) {
      throw new AccountError(AccountErrorCodes.UserNotFound);
    }

    const userRef = doc(this.firebase.firestore, 'users', currentUser.uid);
    await updateDoc(userRef, {
      currentAccountId: deleteField(),
    });
  }

  async createAccount(name: string, isDefault = false, organizationId?: string): Promise<Account> {
    const currentUser = this.firebase.auth.currentUser;

    if (!currentUser) {
      throw new Error('AccountService: User not logged in');
    }

    const accessToken = this.generateToken();
    const secretKey = this.generateToken();

    const userId = currentUser.uid;
    const developerRef = doc(collection(this.firebase.firestore, 'developers'));
    const userRef = doc(this.firebase.firestore, 'users', userId);

    // Only fetch an organization if the account is not the default account
    if (!isDefault && !organizationId) {
      const currentAccount = await this.getCurrentAccount();
      organizationId = currentAccount.organizationId;
    }

    const transactionFn = runTransaction.bind(this, this.firebase.firestore, async (transaction) => {
      const accountData: Omit<Account, 'id'> = {
        name,
        ownerId: userId,
        vendorId: developerRef.id,
        accessToken,
        secretKey,
        authorizedDomains: [],
        features: [],
        createdAt: serverTimestamp(),
      };

      if (organizationId) {
        accountData['organizationId'] = organizationId;
      }

      transaction.set(developerRef, accountData);

      if (!isDefault || organizationId) {
        return;
      }

      transaction.update(userRef, {
        licenseAccepted: true,
        licenseAcceptedAt: serverTimestamp(),
        defaultAccountId: developerRef.id,
      });

    });

    await runWithRetry(transactionFn);

    return {
      id: developerRef.id,
      name,
      ownerId: userId,
      vendorId: developerRef.id,
      createdAt: Date.now(),
      accessToken,
      organizationId,
      secretKey,
      authorizedDomains: [],
      features: [],
    }
  }

  async createDefaultAccount(): Promise<Account> {
    const currentAccount = await this.createAccount(this.DEFAULT_ACCOUNT_NAME, true);
    this.dataStore.updateState({ currentAccount });
    this.setCurrentAccountInSession(currentAccount.id);

    return currentAccount;
  }

  clearCurrentAccount(): void {
    this.dataStore.removeAuthStateProperty(this.currentAccountKey);
  }

  getAccountRef(accountId: string) {
    return doc(this.firebase.firestore, 'developers', accountId);
  }

  updateOrganizationOnAccount(organizationId: any) {
    const account = this.dataStore.getProperty('currentAccount')
    if (!account) {
      throw new Error('AccountService: Current account not set');
    }

    updateDoc(this.getAccountRef(account.id), {
      organizationId
    });
  }

  async getDefaultAccount(): Promise<Account> {
    try {
      const accountId = await this.userService.getDefaultAccountId();
      return this.getAccount(accountId);
    } catch (e) {
      return defaultAccount;
    }
  }

  async getAccount(accountId: string): Promise<Account> {
    if (!this.firebase.auth.currentUser) {
      throw new AccountError(AccountErrorCodes.UserNotLoggedIn);
    }

    const accountDoc = await getDoc(doc(this.firebase.firestore, 'developers', accountId));
    const data = accountDoc.data();

    if (!data) {
      throw new AccountError(AccountErrorCodes.AccountNotFound);
    }

    const { name, ownerId, vendorId, organizationId, accessToken, authorizedDomains, secretKey, features, createdAt, } = data;

    if (!name) {
      throw new AccountError(AccountErrorCodes.AccountNotFound);
    }

    const account = {
      id: accountId,
      name,
      ownerId,
      vendorId,
      organizationId,
      accessToken,
      authorizedDomains,
      secretKey,
      features,
      createdAt,
    };

    return account;
  }

  async getCurrentAccount(forceFetch = false): Promise<Account> {
    const inMemoryAccount = this.dataStore.getProperty('currentAccount');
    if (!forceFetch && inMemoryAccount) {
      return inMemoryAccount;
    }

    await this.firebase.auth.authStateReady();

    if (!this.firebase.auth.currentUser) {
      throw new AccountError(AccountErrorCodes.UserNotLoggedIn);
    }

    let accountId = this.getCurrentAccountIdFromSession();

    if (!accountId) {
      accountId = await this.userService.getCurrentAccountId();
    }

    // TODO org - Added this to handle cases where the current account in session is not found
    // We then default to the default account, but it's quite messy. We should probably handle this better
    // Maybe we can remove this once the DataStore properly handles the state
    // Or maybe this.getAccount should handle this better and return the default account
    let currentAccount: Account;
    try {
      currentAccount = await this.getAccount(accountId);
    } catch (e) {
      try {
        const defaultAccountId = await this.userService.getDefaultAccountId();
        currentAccount = await this.getAccount(defaultAccountId);
      } catch (e) {
        currentAccount = defaultAccount;
      }
    }

    this.setCurrentAccountInSession(currentAccount.id)
    this.dataStore.updateState({ currentAccount });

    if (currentAccount.vendorId === '') {
      await this.createDefaultAccount();
      return this.getCurrentAccount();
    }

    if (!this.apiKey && currentAccount.secretKey) {
      const apiKey = await this.getApiKey(currentAccount.secretKey);
      if (apiKey) {
        this.apiKey = apiKey;
      } else {
        await this.createApiKey(currentAccount.secretKey, currentAccount.vendorId);
        this.apiKey = currentAccount.secretKey;
      }
    }

    return currentAccount;
  }

  async getCurrentContext(): Promise<'organization' | 'personal'> {
    const currentAccount = await this.getCurrentAccount();

    return currentAccount.organizationId ? 'organization' : 'personal';
  }

  async updateAccountName(name: string) {
    let currentAccount = this.dataStore.getProperty('currentAccount');
    if (!currentAccount) {
      currentAccount = await this.getCurrentAccount();
   }

    try {
      await setDoc(doc(this.firebase.firestore, 'developers', currentAccount.id), { name }, { merge: true });
      currentAccount.name = name;
      this.dataStore.updateState({ currentAccount });
    } catch (e) {
      console.error(e);
      throw new Error('AccountService: Failed to update account name');
    }
  }

  async setCurrentAccount(accountId: string) {
    const account = await this.getAccount(accountId);
    await this.updateAccountInState(account);
  }

  async updateAccountInState(account: Account, organization?: Organization) {
    await this.setCurrentAccountInSession(account.id);
    const state: Partial<State> = { currentAccount: account };
    if (organization) {
      state['organization'] = organization;
    }

    const currentUser = this.dataStore.getProperty('currentUser');
    const organizationIds = currentUser?.organizationIds || [];
    if (currentUser && account.organizationId && !organizationIds.includes(account.organizationId)) {
      currentUser.organizationIds = [...organizationIds, account.organizationId];
      state['currentUser'] = currentUser;
    }

    this.dataStore.updateState(state);
  }

  async createAndLinkAccount(userId: string, organizationId: string, accountName: string): Promise<void> {
    const db = this.firebase.firestore;

    const accountRef = doc(collection(db, 'developers')); // Create a new account reference

    // Set the new account's data
    await setDoc(accountRef, {
      name: accountName,
      ownerId: userId,
      organizationId: organizationId,
      createdAt: serverTimestamp()
    });
  }

  async listAccountsByOrganization(organizationId: string): Promise<Account[]> {
    const db = this.firebase.firestore;
    const accountsRef = collection(db, 'developers');
    const q = query(accountsRef, where('organizationId', '==', organizationId));

    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      return [];
    }

    return querySnapshot.docs.map(doc => {
      const data = doc.data();
      const { name, ownerId, vendorId, organizationId, accessToken, authorizedDomains, secretKey, features, createdAt, } = data;
      return {
        id: doc.id,
        name,
        ownerId,
        vendorId,
        organizationId,
        accessToken,
        authorizedDomains,
        secretKey,
        features,
        createdAt,
      };
    });
  }

  async generateSecretKey(userId: string) {
    const secretKey = this.generateToken();
    setDoc(doc(this.firebase.firestore, 'developers', userId), {
      secretKey,
    }, { merge: true });

    return secretKey;
  }

  async getApiKey(key: string) {
    const keyDoc = await getDoc(doc(this.firebase.firestore, 'apikeys', key));
    if (keyDoc.exists()) {
      return key;
    }
    return;
  }

  async createApiKey(key: string, vendorId: string) {
    await setDoc(doc(this.firebase.firestore, 'apikeys', key), {
      vendorId,
    });
  }

  async addAuthorizedDomain(domain: string) {
    const currentAccount = await this.getCurrentAccount();
    await setDoc(doc(this.firebase.firestore, 'developers', currentAccount.id), {
      authorizedDomains: arrayUnion(domain),
    }, { merge: true });

    // The account page holds a direct reference to the current account's authorized domains;
    // As such they have already been updated in the data store. We now publish to update to the subscribers.
    this.dataStore.updateState({ currentAccount });
  }

  async findClusterIdFromAIContext(genAIContextId: string): Promise<string> {
    const currentAccount = await this.getCurrentAccount();
    const mistletsRef = collection(this.firebase.firestore, 'developers', currentAccount.id, 'resources', 'agent', 'items');
    const querySnapshot = await getDocs(mistletsRef);
    const mistletsData = querySnapshot.docs.map(doc => ({ id: doc.id, data: doc.data() }));

    const mistlets = mistletsData.map((mistletData: any) => {
      const { id, data: { mistlets = [], clusters = [] } } = mistletData;
      return {
        id,
        mistlets,
        clusters,
      };
    });

    for (const mistlet of mistlets) {
      const clusters = mistlet.clusters;
      for (const cluster of clusters) {
        for (const contextId of cluster.generativeAIContexts) {
          if (contextId === genAIContextId) {
            return cluster.id;
          }
        }
      }
    }

    throw new AccountError(AccountErrorCodes.ClusterNotFound);
  }

  async removeAuthorizedDomain(domain: string) {
    const currentAccount = await this.getCurrentAccount();
    await setDoc(doc(this.firebase.firestore, 'developers', currentAccount.id), {
      authorizedDomains: arrayRemove(domain),
    }, { merge: true });
  }

  private generateToken(): string {
    const specials = '!@#$%^&*_+:<>?[];,.~';
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const all = specials + lowercase + uppercase + numbers;

    let token = '';
    token += this.pick(specials, 3);
    token += this.pick(lowercase, 3);
    token += this.pick(uppercase, 3);
    token += this.pick(all, 11, 23);
    token = this.shuffle(token);

    return token;
  }

  private pick(chars: string, min: number, max?: number): string {
    let n, picked = '';

    if (typeof max === 'undefined') {
      n = min;
    } else {
      n = min + Math.floor(Math.random() * (max - min + 1));
    }

    for (let i = 0; i < n; i++) {
      picked += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return picked;
  }

  private shuffle(token: string): string {
    let array = token.split('');
    let tmp, current, top = array.length;

    if (top) while (--top) {
      current = Math.floor(Math.random() * (top + 1));
      tmp = array[current];
      array[current] = array[top];
      array[top] = tmp;
    }

    return array.join('');
  }
}
